// src/components/forms/FormFieldRenderer.js

import React from "react";
import { TextField, Switch, FormControl, FormControlLabel, FormHelperText, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import GenericAutocomplete from "../lookup/GenericAutocomplete";
import CountrySelector from "./CountrySelector";
import FileDropzone from "../FileDropzone"; // Assuming you have this component

const FormFieldRenderer = ({ field }) => {
    const { control } = useFormContext();

    // This function renders the appropriate field based on its type
    const renderField = (fieldProps) => {
        const { type } = field;
        const { onChange, value, error } = fieldProps;

        switch (type) {
            case 'fileDropzone':
                return (
                    <FormControl error={!!error} fullWidth>
                        <FileDropzone
                            onDrop={(file) => {
                                onChange([file]); // Assuming single file upload
                            }}
                            file={value && value.length > 0 ? value[0] : null}
                            accept={field.accept}
                            label={field.label}
                        />
                        {error && (
                            <FormHelperText>
                                {error.message || 'Please select a file'}
                            </FormHelperText>
                        )}
                    </FormControl>
                );

            case 'autocomplete':
                return (
                    <GenericAutocomplete
                        {...field}
                        value={value}
                        onChange={onChange}
                        error={error}
                    />
                );

            case 'select':
                return (
                    <TextField
                        select
                        fullWidth
                        label={field.label}
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        error={!!error}
                        helperText={error ? `${field.label} is required` : ''}
                    >
                        {field.options?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );

            case 'switch':
                return (
                    <FormControl error={!!error}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!!value}
                                    onChange={(e) => onChange(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={field.label}
                        />
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                );

            case 'countrySelector':
                return (
                    <CountrySelector
                        name={field.name}
                        control={control}
                        rules={field.rules}
                    />
                );

            case 'date':
                return (
                    <DatePicker
                        label={field.label}
                        value={value || null}
                        onChange={(date) => {
                            onChange(date);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                        inputFormat="yyyy-MM-dd" // Ensure format matches backend expectation
                        disableFuture={false} // Adjust as needed
                    />
                );

            default:
                return (
                    <TextField
                        fullWidth
                        label={field.label}
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        error={!!error}
                        helperText={error?.message}
                    />
                );
        }
    };

    return (
        <Controller
            name={field.name}
            control={control}
            rules={{
                required: field.required ? `${field.label} is required` : false,
                // Add other validation rules as needed
            }}
            render={({ field: { ref, ...fieldProps }, fieldState: { error } }) =>
                renderField({ ...fieldProps, error })}
        />
    );
};

export default FormFieldRenderer;
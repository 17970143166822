import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from "../api/apiService";

export const preloadData = createAsyncThunk(
    'autocomplete/preloadData',
    async ({ endpoint, dataKey, params = {} }, { rejectWithValue }) => {
        try {
            const response = await apiService.get(endpoint, params);
            return { key: endpoint, data: response[dataKey] }; // Return key and data explicitly
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const autocompleteSlice = createSlice({
    name: 'autocomplete',
    initialState: {
        options: {}, // Store options grouped by endpoint key
        loading: false,
        error: null,
    },
    reducers: {
        setOptions(state, action) {
            const { key, options } = action.payload;
            state.options[key] = options;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(preloadData.pending, (state) => {
                state.loading = true;
            })
            .addCase(preloadData.fulfilled, (state, action) => {
                const { key, data } = action.payload; // Destructure key and data
                state.loading = false;
                state.options[key] = data; // Store options under the corresponding key
            })
            .addCase(preloadData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setOptions } = autocompleteSlice.actions;
export default autocompleteSlice.reducer;
// src/components/BusinessEntitiesList.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessEntities, resetBusinessEntities } from '../../store/businessEntitiesSlice';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Alert,
    Grid,
    Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataCard from "../../components/data/DataCard";
import ItemsPerPageSelector from "../../components/ItemsPerPageSelector";

const BUSINESS_ENTITY_SCHEMA = {
    header: [
        {
            key: 'name',
            variant: 'h6',
            color: 'text.primary',
            sx: { fontWeight: 'bold', mb: 1 },
        },
    ],
    chip: { key: 'id', color: 'primary' },
};

const BusinessEntitiesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { items, loading, error, pagination } = useSelector((state) => state.businessEntities);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        const params = { offset: (page - 1) * itemsPerPage, limit: itemsPerPage };
        dispatch(fetchBusinessEntities({ params }));

        return () => {
            dispatch(resetBusinessEntities());
        };
    }, [dispatch, page, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setPage(1);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box p={2}>
            {/* Header Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">Business Entities</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/business-entities/create')}
                >
                    Create New Business Entity
                </Button>
            </Box>

            {/* Items Per Page Selector */}
            <ItemsPerPageSelector
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
            />

            {items.length > 0 ? (
                <>
                    {/* Grid of DataCards */}
                    <Grid container spacing={3} mb={4}>
                        {items.map((entity) => (
                            <Grid item xs={12} sm={6} md={4} key={entity.id}>
                                <DataCard
                                    data={entity}
                                    schema={BUSINESS_ENTITY_SCHEMA}
                                    navigateTo={`/business-entities/${entity.id}`}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    {/* Pagination Controls */}
                    <Box display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(pagination.rowCount / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <Typography>No business entities available.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default BusinessEntitiesList;
import createEntitySlice from './base/baseEntitySlice';

const {reducer, actions} = createEntitySlice({
    name: 'writers',
    endpoint: '/writers',
});

export const {
    fetchEntities: fetchWriters,
    fetchEntityById: fetchWriterById,
    createEntity: createWriter,
    updateEntity: updateWriter,
    deleteEntity: deleteWriter,
    resetState: resetWriters,
} = actions;

export default reducer;
import {getDownloadURL, getStorage, ref} from "firebase/storage";

const storage = getStorage(undefined, "gs://bfm-digital-master-store");
export const FileFetch = async (relativePath) => {
    try {
        console.log("Fetching file:", relativePath);
        const fileRef = ref(storage, relativePath);
        const url = await getDownloadURL(fileRef);
        console.log("Fetched URL:", url);
        return url;
    } catch (error) {
        console.error("Error fetching download URL:", error.message);
        throw error;
    }
};
// src/components/data/DataCard.js

import React from 'react';
import { Card, CardContent, Typography, Chip, Box, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * DataCard Component
 *
 * Displays data in a card format with a header, card data, chip, and navigable action area.
 *
 * @param {Object} props - Component props
 * @param {Object} props.data - Data to display in the card
 * @param {Object} props.schema - Schema configuration for rendering data
 * @param {string} props.navigateTo - Path to navigate to on card click
 */
const DataCard = ({ data, schema, navigateTo }) => {
    const { header, cardData, chip } = schema;
    const navigate = useNavigate();

    const handleCardClick = () => {
        if (navigateTo) {
            navigate(navigateTo);
        }
    };

    return (
        <Card
            variant="outlined"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                ':hover': {
                    boxShadow: 6,
                },
                transition: 'box-shadow 0.3s ease-in-out',
            }}
        >
            {/* Make the entire card clickable */}
            <CardActionArea
                onClick={handleCardClick}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    alignItems: 'flex-start',
                    p: 2,
                }}
            >
                {/* Header Section */}
                {header && header.map((item, index) => (
                    <Typography
                        key={index}
                        variant={item.variant}
                        color={item.color}
                        sx={item.sx}
                    >
                        {data[item.key]}
                    </Typography>
                ))}

                {/* Card Data Section */}
                {cardData && cardData.map((item, index) => (
                    <Typography
                        key={index}
                        variant={item.variant}
                        color={item.color}
                        sx={{ mt: 1 }}
                    >
                        <strong>{item.label}:</strong> {data[item.key]}
                    </Typography>
                ))}

                {/* Spacer to push Chip to the bottom */}
                <Box sx={{ flexGrow: 1 }} />

                {/* Chip Section */}
                {chip && data[chip.key] && (
                    <Chip
                        label={data[chip.key]}
                        color={chip.color || 'default'}
                        variant="outlined"
                        sx={{
                            mt: 2,
                            alignSelf: 'flex-start',
                        }}
                    />
                )}
            </CardActionArea>
        </Card>
    );
};

export default DataCard;
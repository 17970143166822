import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Chip,
    CircularProgress,
    Alert,
    Tooltip,
    IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import { DownloadSimple, Eye } from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { FileFetch } from '../FileFetch';
import apiService from '../../api/apiService';

const typeColors = {
    Marketing: 'green',
    Artwork: 'blue',
    Contracts: 'purple',
    Other: 'grey',
};

const AttachmentsComponent = ({ entityLabel }) => {
    const navigate = useNavigate();
    const { id: entityId } = useParams();
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAttachments = async () => {
            try {
                setLoading(true);
                setError(null);

                const response = await apiService.get('/attachments', { entityId });
                const data = response.attachments || [];

                setAttachments(
                    data.map((attachment, index) => ({
                        id: index + 1,
                        name: attachment.filename,
                        type: attachment.attachment_type,
                        category: attachment.attachment_category,
                        entity_label: entityLabel,
                        uploaded_by: attachment.uploaded_by,
                        uploaded_at: attachment.uploaded_at,
                        relativePath: attachment.blob_url,
                    }))
                );
            } catch (err) {
                setError(err.response?.data || { detail: 'Failed to fetch attachments' });
            } finally {
                setLoading(false);
            }
        };

        fetchAttachments();
    }, [entityId]);

    const handleManageAttachments = () => {
        navigate(`${window.location.pathname}/attachments/manage`);
    };

    const handleView = async (relativePath) => {
        try {
            if (!relativePath) {
                alert('No file path available for this attachment.');
                return;
            }
            const secureUrl = await FileFetch(relativePath);
            window.open(secureUrl, '_blank');
        } catch (error) {
            alert('Failed to fetch the file for viewing.');
        }
    };

    const handleDownload = async (relativePath, name) => {
        try {
            if (!relativePath) {
                alert('No file path available for this attachment.');
                return;
            }
            const secureUrl = await FileFetch(relativePath);
            const link = document.createElement('a');
            link.href = secureUrl;
            link.download = name || relativePath.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            alert('Failed to fetch the file for download.');
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        const errorMessage = error.detail || 'An unexpected error occurred.';
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    const columns = [
        {
            field: 'name',
            headerName: 'File Name',
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Attachment Type',
            flex: 1,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    style={{ backgroundColor: typeColors[params.value] || 'grey', color: 'white' }}
                />
            ),
        },
        {
            field: 'uploaded_by',
            headerName: 'Uploaded By',
            flex: 1,
        },
        {
            field: 'uploaded_at',
            headerName: 'Uploaded At',
            flex: 1,
            renderCell: (params) => {
                const timestamp = params.value;
                return dayjs(timestamp).isValid()
                    ? dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
                    : 'Invalid Date';
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="View">
                        <IconButton onClick={() => handleView(params.row.relativePath)}>
                            <Eye size={23} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                        <IconButton onClick={() => handleDownload(params.row.relativePath, params.row.name)}>
                            <DownloadSimple size={23} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Attachments</Typography>
                <Tooltip title="Manage Attachments">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={handleManageAttachments}
                    >
                        Manage Attachments
                    </Button>
                </Tooltip>
            </Box>
            <DataGrid
                rows={attachments}
                columns={columns}
                autoHeight
                disableSelectionOnClick
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                // sx={{ backgroundColor: 'white' }}
            />
        </Box>
    );
};

export default AttachmentsComponent;
// src/components/data/GenericEntityView.js
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setActiveTab } from '../../store/tabSlice';
import {
    Box,
    Button,
    Typography,
    CircularProgress,
    Alert,
    Paper,
    Tabs,
    Tab,
    Grid,
    TextField,
    IconButton,
    Tooltip,
} from '@mui/material';
import { LockLaminated, LockLaminatedOpen } from "@phosphor-icons/react";

const GenericEntityView = ({
                               schema,
                               tabSchema,
                               fetchData,
                               updateData,
                               loading: parentLoading,
                               error: parentError,
                               entityData,
                               control
                           }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeTab } = useSelector((state) => state.tabs);

    const [item, setItem] = useState(entityData || null);
    const [loading, setLoading] = useState(parentLoading || true);
    const [error, setError] = useState(parentError || null);
    const [isEditable, setIsEditable] = useState(false);

    const titleField = schema.find(field => field.titleKey);

    useEffect(() => {
        const fetchEntityData = async () => {
            if (!entityData && fetchData && id) {
                setLoading(true);
                setError(null);
                try {
                    const data = await fetchData(id);
                    setItem(data);
                } catch (err) {
                    setError(err.message || 'Error fetching entity');
                } finally {
                    setLoading(false);
                }
            } else if (entityData) {
                setLoading(false);
            }
        };

        fetchEntityData();
    }, [fetchData, id, entityData]);

    const handleUnlockToggle = () => {
        setIsEditable(prev => !prev);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    const renderTopFields = () => {
        const rowGroups = schema.reduce((acc, field) => {
            if (!field.titleKey) {
                const rowPos = field.rowPos || 0;
                if (!acc[rowPos]) {
                    acc[rowPos] = [];
                }
                acc[rowPos].push(field);
            }
            return acc;
        }, {});

        return Object.entries(rowGroups).map(([rowPos, fields]) => (
            <Grid container spacing={4} key={rowPos} sx={{ mb: 4 }}>
                {fields.map((field) => (
                    <Grid item xs={12} sm={6} md={field.gridSize || 3} key={field.dataKey}>
                        <Controller
                            name={field.dataKey}
                            control={control}
                            render={({ field: controllerField }) =>
                                field.render ?
                                    field.render(controllerField, isEditable, field) :
                                    <TextField
                                        {...controllerField}
                                        label={field.label}
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                        disabled={!isEditable || !field.editable}
                                        InputLabelProps={{ shrink: true }}
                                    />
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        ));
    };

    return (
        <Box p={2}>
            <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box flexGrow={1} mr={2}>
                        {titleField && (
                            <Controller
                                name={titleField.dataKey}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        fullWidth
                                        disabled={!isEditable}
                                        InputProps={{
                                            style: { fontSize: '1.5rem' },
                                            disableUnderline: true,
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '1.5rem',
                                                height: '2rem',
                                            }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                    <Tooltip title={isEditable ? 'Lock Record' : 'Unlock Record'}>
                        <IconButton onClick={handleUnlockToggle} color="primary">
                            {isEditable ? <LockLaminatedOpen size={23} /> : <LockLaminated size={23} />}
                        </IconButton>
                    </Tooltip>
                </Box>
                {renderTopFields()}
                {isEditable && (
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{ mr: 2 }}
                            onClick={() => { /* Implement a cancel logic if needed */ }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Save Changes
                        </Button>
                    </Box>
                )}
            </Paper>

            <Tabs
                value={activeTab}
                onChange={(event, newTab) => dispatch(setActiveTab(newTab))}
                sx={{ mb: 3 }}
            >
                {tabSchema.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start" />
                ))}
            </Tabs>

            <Paper elevation={1} sx={{ p: 2 }}>
                {React.createElement(tabSchema[activeTab]?.component, { item, control })}
            </Paper>
        </Box>
    );
};

export default GenericEntityView;
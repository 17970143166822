const ATTACHMENT_CATEGORIES = {
    Images: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff'],
    Audio: ['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a', 'wma', 'aiff'],
    Videos: ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'flv', 'webm'],
    Documents: ['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt', 'ppt', 'pptx', 'xls', 'xlsx', 'csv'],
    Archives: ['zip', 'rar', '7z', 'tar', 'gz', 'bz2'],
    Code: ['js', 'ts', 'html', 'css', 'json', 'xml', 'py', 'java', 'c', 'cpp', 'cs', 'php'],
    Misc: [],
};

export const getCategoryFromFileName = (fileName) => {
    if (!fileName) return 'Misc';
    const extension = fileName.split('.').pop().toLowerCase();
    for (const [category, extensions] of Object.entries(ATTACHMENT_CATEGORIES)) {
        if (extensions.includes(extension)) {
            return category;
        }
    }
    return 'Misc';
};

export const getSubTypesForCategory = (category) => {
    const SUBTYPES = {
        Images: ['Marketing', 'Artwork', 'Other'],
        Audio: ['Master Recording', 'Acapella', 'Instrumental', 'Unreleased', 'Other'],
        Videos: ['Promotional', 'Full-Length', 'Other'],
        Documents: ['Contracts', 'Schedule of Work', 'Other'],
        Archives: ['Compressed Backup', 'Other'],
        Code: ['Source Code', 'Configuration', 'Other'],
        Misc: ['Other'],
    };
    return SUBTYPES[category] || [];
};
import React from 'react';
import {Select, MenuItem, FormControl, InputLabel, Divider, Container, Box} from '@mui/material';

const ItemsPerPageSelector = ({itemsPerPage, onItemsPerPageChange, options = [9, 18, 36, 90]}) => {
    const handleChange = (event) => {
        onItemsPerPageChange(event.target.value); // Pass the selected value to parent component
    };

    return (
        <Box mb={'1vh'}>
            <FormControl sx={{width: '10vw'}}>
                <InputLabel id="items-per-page-label">Items per Page</InputLabel>
                <Select
                    labelId="items-per-page-label"
                    value={itemsPerPage}
                    onChange={handleChange}
                    label="Items per Page"
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default ItemsPerPageSelector;
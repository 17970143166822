// src/views/works/WorkView.js
import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import GenericEntityView from '../../components/data/GenericEntityView';
import { BFMTemplatePage } from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import CountrySelector from '../../components/forms/CountrySelector';
import PublishingRightsForm from '../../components/forms/PublishingRightsForm';

import { Handshake, Paperclip, Notepad, Gear } from "@phosphor-icons/react";
import { fetchWorkById, updateWork } from '../../store/workSlice';

const WORK_SCHEMA = [
    { dataKey: 'id', label: 'Work ID', editable: false },
    { dataKey: 'name', label: 'Name', editable: true, titleKey: true },
    { dataKey: 'iswc', label: 'ISWC', editable: true },
    { dataKey: 'reversion_date', label: 'Reversion Date', editable: true },
    { dataKey: 'rights', label: 'Rights', editable: true },
];

const WorkView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { item: work, loading, error } = useSelector((state) => state.works);

    const fetchData = useCallback(
        async (id) => {
            return await dispatch(fetchWorkById(id)).unwrap();
        },
        [dispatch]
    );

    const updateData = useCallback(
        async (id, data) => {
            return await dispatch(updateWork({ id, data })).unwrap();
        },
        [dispatch]
    );

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id, fetchData]);

    // Combine publishers and writers into a single rights array
    const combinedRights = work ? [
        ...(work.publishers || []).map(pub => ({
            ...pub,
            type: "Publisher Share",
        })),
        ...(work.writers || []).map(writer => ({
            ...writer,
            type: "Writer Share",
        }))
    ] : [];

    const methods = useForm({
        defaultValues: {
            id: work?.id || '',
            name: work?.name || '',
            iswc: work?.iswc || '',
            reversion_date: work?.reversion_date || '',
            rights: combinedRights,
        }
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data) => {
        await updateData(id, data);
    };

    const tabSchema = useMemo(
        () => [
            {
                label: 'Rights Management',
                icon: <Handshake size={23} />,
                component: PublishingRightsForm,
            },
            {
                label: 'Territories',
                icon: <Paperclip size={23} />,
                component: CountrySelector,
            },
            {
                label: 'Attachments',
                icon: <Paperclip size={23} />,
                component: AttachmentsComponent,
            },
            {
                label: 'Notes',
                icon: <Notepad size={23} />,
                component: NotesComponent,
            },
            {
                label: 'Metadata',
                icon: <Gear size={23} />,
                component: MetadataComponent,
            },
        ],
        []
    );

    return (
        <BFMTemplatePage
            component={
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GenericEntityView
                            schema={WORK_SCHEMA}
                            tabSchema={tabSchema}
                            entityName="Work"
                            title="Work"
                            fetchData={fetchData}
                            updateData={updateData}
                            loading={loading}
                            error={error}
                            entityData={work}
                            control={methods.control}
                        />
                    </form>
                </FormProvider>
            }
        />
    );
};

export default WorkView;
import React from 'react';
import {Box, Typography} from '@mui/material';
import {useDropzone} from 'react-dropzone';

const FileDropzone = ({onDrop, file, fileUrl}) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles[0]),
        accept: {
            'image/*': [],
            'application/*': [],
            'audio/*': [],
            'video/*': [],
            'text/*': []
        },
        maxFiles: 1,
    });

    const renderPreview = () => {
        if (file) {
            if (file.type?.startsWith('image/')) {
                return (
                    <Box
                        component="img"
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '100px',
                            borderRadius: '8px',
                        }}
                    />
                );
            }
            return <Typography variant="body2">{file.name}</Typography>;
        }
        if (fileUrl) {
            return <Typography variant="body2">File already uploaded</Typography>;
        }
        return (
            <>
                <Typography variant="body2">
                    {isDragActive ? 'Drop the file here' : 'Drop a file here or click to upload'}
                </Typography>
            </>
        );
    };

    return (
        <>
            <Typography variant='h5'>File</Typography>
            <Box
                {...getRootProps()}
                sx={{
                    width: '100%',
                    minHeight: 150,
                    border: '2px dashed',
                    borderColor: isDragActive ? 'primary.main' : 'grey.400',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    // backgroundColor: isDragActive ? 'action.hover' :
                    //     file || fileUrl ? '#e3f2fd' : '#fafafa',
                    transition: 'all 0.2s',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    },
                    p: 2,
                    textAlign: 'center'
                }}
            >
                <input {...getInputProps()} />
                {renderPreview()}

            </Box>
        </>
    );
};

export default FileDropzone;
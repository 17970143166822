import createEntitySlice from './base/baseEntitySlice';

const { reducer, actions } = createEntitySlice({
    name: 'businessEntities',
    endpoint: '/business-entities',
});

export const {
    fetchEntities: fetchBusinessEntities,
    fetchEntityById: fetchBusinessEntityById,
    createEntity: createBusinessEntity,
    updateEntity: updateBusinessEntity,
    deleteEntity: deleteBusinessEntity,
    resetState: resetBusinessEntities,
} = actions;

export default reducer;
import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import GenericEntityView from '../../components/data/GenericEntityView';
import { BFMTemplatePage } from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import DealsList from "../deals/DealsList";
import { Gear, Handshake, Notepad, Paperclip } from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessEntityById, updateBusinessEntity } from '../../store/businessEntitiesSlice';
import { useForm } from 'react-hook-form';

const BUSINESS_ENTITY_SCHEMA = [
    { dataKey: 'id', label: 'Business Group ID' },
    { dataKey: 'name', label: 'Name', editable: true, titleKey: true },
];

const BusinessEntityView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { item: businessEntity, loading, error } = useSelector((state) => state.businessEntities);

    const { control, handleSubmit, reset } = useForm({
        defaultValues: businessEntity || {}
    });

    const fetchData = useCallback(
        async (id) => {
            try {
                const data = await dispatch(fetchBusinessEntityById(id)).unwrap();
                reset(data); // Populate the form with fetched data
                return data;
            } catch (err) {
                console.error("Error fetching business entity:", err);
                throw err;
            }
        },
        [dispatch, id, reset]
    );

    // Update data handler
    const updateDataHandler = useCallback(
        async (data) => {
            try {
                await dispatch(updateBusinessEntity({ id, data })).unwrap();
            } catch (err) {
                console.error("Error updating business entity:", err);
                throw err;
            }
        },
        [dispatch, id]
    );

    // Form submission handler
    const onSubmit = async (data) => {
        await updateDataHandler(data);
    };

    const tabSchema = useMemo(
        () => [
            {
                label: 'Deals',
                icon: <Handshake size={23} />,
                component: () => <DealsList businessEntityId={id} />,
            },
            {
                label: 'Attachments',
                icon: <Paperclip size={23} />,
                component: AttachmentsComponent,
            },
            {
                label: 'Notes',
                icon: <Notepad size={23} />,
                component: NotesComponent,
            },
            {
                label: 'Metadata',
                icon: <Gear size={23} />,
                component: MetadataComponent,
            },
        ],
        [id]
    );

    return (
        <BFMTemplatePage
            component={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GenericEntityView
                        schema={BUSINESS_ENTITY_SCHEMA}
                        tabSchema={tabSchema}
                        entityName="Business Entity"
                        title="Business Entity"
                        fetchData={fetchData}
                        updateData={updateDataHandler}
                        loading={loading}
                        error={error}
                        entityData={businessEntity}
                        control={control} // Pass the control prop
                    />
                    {/* Add a submit button if not included in GenericEntityView */}
                </form>
            }
        />
    );
};

export default BusinessEntityView;
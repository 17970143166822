// src/components/DealsList.js

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDeals, resetDeals} from "../../store/dealSlice";
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Alert,
    Grid,
    Pagination,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import DataCard from "../../components/data/DataCard";
import ItemsPerPageSelector from "../../components/ItemsPerPageSelector";
import {fetchWorks, resetWorks} from "../../store/workSlice";

const WORK_CARD_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'iswc', label: 'ISWC', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};


const WorksList = ({businessEntityId = null}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {items, loading, error, pagination} = useSelector((state) => state.works);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        const params = {
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
            ...(businessEntityId && {businessEntityId: businessEntityId}),
        };

        dispatch(fetchWorks({params}));

        return () => {
            if (!businessEntityId) {
                dispatch(resetWorks());
            }
        };
    }, [businessEntityId, page, itemsPerPage, dispatch]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setPage(1);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box p={2}>
            {/* Header Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">Works</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/works/create')}
                >
                    Create New Deal
                </Button>
            </Box>

            {/* Items Per Page Selector */}
            <ItemsPerPageSelector
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
            />

            {items.length > 0 ? (
                <>
                    {/* Grid of DataCards */}
                    <Grid container spacing={3} mb={4}>
                        {items.map((work) => (
                            <Grid item xs={12} sm={6} md={4} key={work.id}>
                                <DataCard
                                    data={work}
                                    schema={WORK_CARD_SCHEMA}
                                    navigateTo={`/publishing/works/${work.id}`}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    {/* Pagination Controls */}
                    <Box display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(pagination.rowCount / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <Typography>No deals available.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default WorksList;
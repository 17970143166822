import {Route, Routes} from "react-router-dom";
import BusinessEntityList from "../pages/businessEntities/BusinessEntityList";
import BusinessEntityCreate from "../pages/businessEntities/BusinessEntityCreate";
import BusinessEntityView from "../pages/businessEntities/BusinessEntityView";
import NoteCreate from "./NoteCreate";
import {BFMTemplatePage} from "../pages/BFMTemplatePage";
import AttachmentCreate from "./attachments/AttachmentManager";
import React from "react";
import UtilitiesPage from "../pages/utilities";
import FileUploader from "./forms/FileUploader";
import DealsList from "../pages/deals/DealsList";
import DealView from "../pages/deals/DealView";
import DealsCreate from "../pages/deals/DealsCreate";
import WritersList from "../pages/writers/WritersList";
import WorksList from "../pages/works/WorksList";
import PublishingRightsForm from "./forms/PublishingRightsForm";
import WorkView from "../pages/works/WorkView";

export const ApiRouter = () => {
    return (
        <Routes>
            <Route path="/business-entities" element={<BusinessEntityList/>}/>
            <Route path="/business-entities/create" element={<BusinessEntityCreate/>}/>
            <Route path="/business-entities/:id" element={<BusinessEntityView/>}/>
            <Route path="/business-entities/:id/notes/create" element={<NoteCreate nodeLabel={'businessEntity'}/>}/>
            <Route path='/business-entities/:id/attachments/manage'
                   element={<BFMTemplatePage component={<AttachmentCreate nodeLabel={'businessEntity'}/>}/>}/>
            <Route path='/utilities' element={<UtilitiesPage/>}/>
            <Route path='/utilities/file-uploader' element={<FileUploader/>}/>
            <Route path='/deals' element={<DealsList/>}/>

            <Route path="/deals/create" element={<DealsCreate/>}/>
            <Route path='/deals/:id' element={<DealView/>}/>
            <Route path='/deals/:id/notes/create' element={<NoteCreate nodeLabel={'deal'}/>}/>
            <Route path='/deals/:id/attachments/manage' element={<AttachmentCreate nodeLabel={'deal'}/>}/>
            <Route path='/publishing/writers' element={<WritersList/>}/>

            <Route path='/publishing/works' element={<WorksList/>}/>
            <Route path='/publishing/works/:id' element={<WorkView/>}/>
        </Routes>
    )
}
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from "./store";
import { Box, CircularProgress, Container } from "@mui/material";
import { initializeAuth } from "./services/authService";
import { ApiRouter } from "./components/ApiRouter";
import SignInScreen from "./components/Auth/SignInScreen";
import Navbar from "./components/navigation/Navbar";
import { preloadData } from './store/autocompleteSlice';
import ThemeProvider from "./components/ThemeProvider";

function AppContent() {
    const dispatch = useDispatch();
    const { user, loading } = useSelector((state) => state.user);

    useEffect(() => {
        const preloadEndpoints = [
            { endpoint: '/business-entities', dataKey: 'businessEntities', key: 'businessEntities', params: { limit: 5 } },
            { endpoint: '/deals', dataKey: 'deals', key: 'deals', params: { limit: 10 } },
            { endpoint: '/publishers', dataKey: 'publishers', key: 'publishers', params: { limit: 10 } },
        ];

        preloadEndpoints.forEach(({ endpoint, dataKey, key, params }) =>
            dispatch(preloadData({ endpoint, dataKey, key, params }))
        );
    }, [dispatch]);

    useEffect(() => {
        initializeAuth(dispatch); // Initialize Firebase auth
    }, [dispatch]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return user ? (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Navbar />
            <Box component="main" flexGrow={1} mt={8}>
                <Container maxWidth="xl">
                    <ApiRouter />
                </Container>
            </Box>
        </Box>
    ) : (
        <SignInScreen />
    );
}

const App = () => (
    <Provider store={store}>
        <ThemeProvider>
            <AppContent />
        </ThemeProvider>
    </Provider>
);

export default App;
// src/services/authService.js
import {
    setPersistence,
    browserLocalPersistence,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import { auth } from "../firebase/config";
import { setUser, clearUser, setLoading } from "../store/userSlice";

let cachedToken = null;
let lastTokenFetchTime = 0;

// Centralized Firebase user parsing logic
const parseFirebaseUser = async (firebaseUser) => {
    const tokenResult = await firebaseUser.getIdTokenResult();
    return {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
        token: tokenResult.token, // Cache the token here
        access_level: tokenResult.claims.access_level || "VIEWER", // Handle custom claims
    };
};

const refreshTokenIfNeeded = async () => {
    console.log("Refreshing token if needed...");
    const now = Date.now();
    const FIVE_MINUTES = 5 * 60 * 1000;

    if (!cachedToken || now - lastTokenFetchTime > FIVE_MINUTES) {
        console.log("Fetching new token...");
        const user = auth.currentUser;
        if (user) {
            cachedToken = await user.getIdToken(true); // Force refresh
            lastTokenFetchTime = now;
        } else {
            cachedToken = null;
        }
    } else {
        console.log("Using cached token.");
    }
    return cachedToken;
};// Initialize Firebase authentication
export const initializeAuth = (dispatch) => {
    console.log("Initializing auth...");
    dispatch(setLoading(true));
    setPersistence(auth, browserLocalPersistence)
        .then(() => {
            console.log("Persistence set successfully.");
            onAuthStateChanged(auth, async (firebaseUser) => {
                console.log("Auth state changed:", firebaseUser);
                if (firebaseUser) {
                    const user = await parseFirebaseUser(firebaseUser);
                    console.log("Parsed user:", user);
                    dispatch(setUser(user));
                } else {
                    console.log("No user signed in.");
                    dispatch(clearUser());
                }
                dispatch(setLoading(false));
            });
        })
        .catch((error) => {
            console.error("Failed to initialize auth:", error);
            dispatch(setLoading(false));
        });
};

// Sign in with Google
export const signInWithGoogle = async (dispatch) => {
    try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const user = await parseFirebaseUser(result.user);
        dispatch(setUser(user));
    } catch (error) {
        console.error("Google Sign-In failed:", error);
        throw new Error("Google Sign-In failed. Please try again.");
    }
};

// Sign out user
export const signOutUser = async (dispatch) => {
    try {
        await signOut(auth);
        dispatch(clearUser()); // Clear user state in Redux
        cachedToken = null; // Clear cached token
    } catch (error) {
        console.error("Sign out error:", error);
        throw new Error("Sign out failed. Please try again.");
    }
};

// Utility to get the current token (cached or refreshed)
export const getCurrentToken = async () => {
    try {
        return await refreshTokenIfNeeded();
    } catch (error) {
        console.error("Failed to refresh token:", error);
        return null;
    }
};
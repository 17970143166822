import baseEntitySlice from "./base/baseEntitySlice";

const {reducer, actions} = baseEntitySlice({
    name: 'notes',
    endpoint: '/notes'
});

export const {
    fetchEntities: fetchNotes,
    createEntity: createNote,
    updateEntity: updateNote,
    deleteEntity: deleteNote,
    resetState: resetNotes
} = actions;

export default reducer;
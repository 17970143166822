import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Typography, MenuItem, TextField, Button, Alert } from '@mui/material';
import {getFormConfig} from "./new/FormRegistry";
import apiService from "../../api/apiService";

const mainCategories = [
    { value: 'scheduleOfWork', label: 'Schedule of Work' },
];

const subCategories = {
    scheduleOfWork: [
        { value: 'publishing', label: 'Publishing' },
        { value: 'recordings', label: 'Recordings' },
    ],
};

const FileUploader = () => {
    const [mainCategory, setMainCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [formConfig, setFormConfig] = useState(null);
    const [error, setError] = useState(null);
    const methods = useForm({
        defaultValues: {}, // Start with an empty defaultValues object
    });

    const handleMainCategoryChange = (event) => {
        setMainCategory(event.target.value);
        setSubCategory('');
        setFormConfig(null);
        setError(null);
    };

    const handleSubCategoryChange = (event) => {
        setSubCategory(event.target.value);
        const config = getFormConfig(mainCategory, event.target.value);
        if (config) {
            setFormConfig(config);
        }
        setError(null);
    };

    const handleSubmit = async (data) => {
        setError(null);
        try {
            const endpoint = formConfig?.endpoint;
            if (!endpoint) throw new Error('No endpoint configured for the selected form.');
            // Call API service to submit form data
            await apiService.post(endpoint, data);
        } catch (submissionError) {
            console.error('Submission Error:', submissionError);
            setError('Submission failed. Please check your input and try again.');
        }
    };

    return (
        <FormProvider {...methods}>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    File Uploader
                </Typography>
                <Box
                    component="form"
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 400 }}
                >
                    <TextField
                        select
                        label="Main Category"
                        value={mainCategory}
                        onChange={handleMainCategoryChange}
                        fullWidth
                    >
                        {mainCategories.map((category) => (
                            <MenuItem key={category.value} value={category.value}>
                                {category.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Subcategory"
                        value={subCategory}
                        onChange={handleSubCategoryChange}
                        fullWidth
                        disabled={!mainCategory}
                    >
                        {mainCategory &&
                            subCategories[mainCategory]?.map((subcategory) => (
                                <MenuItem key={subcategory.value} value={subcategory.value}>
                                    {subcategory.label}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>

                {/* Error Alert */}
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}

                {/* Dynamically render the selected form */}
                {formConfig && (
                    <Box mt={4}>
                        <Typography variant="h6" gutterBottom>
                            Selected Form:
                        </Typography>
                        <formConfig.component />
                    </Box>
                )}
            </Box>
        </FormProvider>
    );
};

export default FileUploader;
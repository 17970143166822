import createEntitySlice from './base/baseEntitySlice';

const { reducer, actions } = createEntitySlice({
    name: 'deals',
    endpoint: '/deals',
});

export const {
    fetchEntities: fetchDeals,
    fetchEntityById: fetchDealById,
    createEntity: createDeal,
    updateEntity: updateDeal,
    deleteEntity: deleteDeal,
    resetState: resetDeals,
} = actions;

export default reducer;
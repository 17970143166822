import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Upload } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

const UTILITIES_OPTIONS = [
    {
        id: 'file-uploader',
        title: 'File Uploader',
        description: 'Upload and manage files efficiently.',
        icon: <Upload size={32} />,
        link: '/utilities/file-uploader',
    },
    // You can add more options here
];

const UtilitiesPage = () => {
    const navigate = useNavigate();

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">Utilities</Typography>
            </Box>

            <Grid container spacing={3}>
                {UTILITIES_OPTIONS.map((option) => (
                    <Grid item xs={12} sm={6} md={4} key={option.id}>
                        <Box
                            sx={{
                                border: '1px solid #e0e0e0',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                transition: 'all 0.3s',
                                '&:hover': {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => navigate(option.link)}
                        >
                            <Box mb={2} display="flex" justifyContent="center">
                                {option.icon}
                            </Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {option.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {option.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default UtilitiesPage;
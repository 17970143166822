import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeTab: 0, // Default to the first tab
    tabs: [],
};

const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        initializeTabs: (state, action) => {
            // Initialize tabs dynamically
            state.tabs = action.payload;
        },
        setTabUnsaved: (state, action) => {
            const { index, unsaved } = action.payload;
            if (state.tabs[index]) {
                state.tabs[index].unsaved = unsaved;
            }
        },
    },
});

export const { setActiveTab, initializeTabs, setTabUnsaved } = tabSlice.actions;

export default tabSlice.reducer;
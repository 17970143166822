// src/schemas/scheduleOfWorkSchema.js
const scheduleOfWorkSchema = [
    {
        step: 0,
        fields: [
            {
                name: 'deal_id',
                type: 'autocomplete',
                label: 'Deal Name',
                placeholder: 'Search or create new Deal',
                endpoint: '/deals',
                dataKey: 'deals',
                createNewUrl: '/deals/create',
                required: true,
            },
            {
                name: 'file',
                type: 'fileDropzone',
                label: 'Upload Schedule',
                accept: 'text/csv',
                required: true,
            },
        ],
    },
    {
        step: 1,
        fields: [
            {
                name: 'territories',
                type: 'countrySelector',
                label: 'Territories',
                required: true,
            },
        ],
    },
    {
        step: 2,
        fields: [
            {
                name: 'right_type',
                type: 'select',
                label: 'Right Type',
                options: [
                    {value: 'writer_share', label: 'Writer Share'},
                    {value: 'publisher_share', label: 'Publisher Share'},
                ],
                required: true,
            },
            {
                name: 'is_controlled',
                type: 'switch',
                label: 'Is Controlled (Exclusive/Active)',

            },
        ],
    },
];

export default scheduleOfWorkSchema;
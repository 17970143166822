// NotesComponent.jsx
import React, { useEffect } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Tooltip, CircularProgress, Alert } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {fetchNotes} from "../../store/noteSlice";

const NotesComponent = ({ entityLabel }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { items: notes, loading, error } = useSelector(state => state.notes);

    useEffect(() => {
        dispatch(fetchNotes({
            params: {
                entityId: id,
                nodeLabel: entityLabel
            }
        }));
    }, [dispatch, id, entityLabel]);
    console.log(notes)

    const sortedNotes = notes;//notes?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="50vh"><CircularProgress /></Box>;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Notes</Typography>
                <Tooltip title="Add a new note">
                    <Button variant="contained" startIcon={<Add />}
                            onClick={() => navigate(`${window.location.pathname}/notes/create`)}>
                        Add Note
                    </Button>
                </Tooltip>
            </Box>
            {sortedNotes?.length ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Note</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedNotes.map(note => (
                                <TableRow key={note.id}>
                                    <TableCell>{note.note}</TableCell>
                                    <TableCell>{note.created_by}</TableCell>
                                    <TableCell>{new Date(note.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No notes available.</Typography>
            )}
        </Box>
    );
};
export default NotesComponent
import {Container} from "@mui/material";

export function BFMTemplatePage({component}) {


    return (
        <>
            <Container>
                {component}
            </Container>
        </>
    )
}
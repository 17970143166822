import {configureStore} from '@reduxjs/toolkit';
import tabsReducer from './tabSlice'
import userReducer from './userSlice.js'
import autocompleteReducer from './autocompleteSlice';
import formReducer from './formSlice'
import dealsReducer from './dealSlice'
import noteReducer from "./noteSlice";
import writersReducer from './writerSlice'
import businessEntitiesReducer from "./businessEntitiesSlice";
import worksReducer from './workSlice'


const store = configureStore({
    reducer: {
        tabs: tabsReducer,
        user: userReducer,
        autocomplete: autocompleteReducer,
        form: formReducer,
        deals: dealsReducer,
        notes: noteReducer,
        businessEntities: businessEntitiesReducer,
        writers: writersReducer,
        works: worksReducer

    },
});

export default store;
// DealView.jsx
import React, {useCallback, useMemo, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {TextField, Typography} from "@mui/material";
import {Archive, Gear, Notepad, Paperclip} from "@phosphor-icons/react";
import {fetchDealById, updateDeal} from '../../store/dealSlice';
import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import GenericAutocomplete from "../../components/lookup/GenericAutocomplete";

const DealView = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [businessEntityInput, setBusinessEntityInput] = useState('');

    const DEAL_SCHEMA = useMemo(() => [
        {dataKey: 'id', label: 'Deal ID', rowPos: 0},
        {dataKey: 'name', label: 'Name', editable: true, titleKey: true},
        {
            dataKey: 'completed_date',
            label: 'Completed Date',
            editable: true,
            rowPos: 1,
            gridSize: 3,
            render: (controllerField, isEditable, field) => (
                <TextField
                    {...controllerField}
                    type="date"
                    label={field.label}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    disabled={!isEditable}
                    InputLabelProps={{shrink: true}}
                />
            ),
        },
        {
            dataKey: 'business_entity_id',
            label: 'Business Entity',
            editable: true,
            rowPos: 2,
            gridSize: 3,
            render: (controllerField, isEditable, field) => (
                <GenericAutocomplete
                    label="Business Entity"
                    placeholder="Search business entities"
                    endpoint="/business-entities"
                    dataKey="businessEntities"
                    createNewUrl="/business-entities/create"
                    value={controllerField.value}
                    onChange={controllerField.onChange}
                    error={controllerField.error}
                    inputRef={controllerField.ref}
                    disabled={!isEditable}
                    onInputChange={(_, value) => setBusinessEntityInput(value)}
                    onCreateNew={() => {
                        navigate('/business-entities/create', {
                            state: {
                                formData: {name: businessEntityInput},
                                returnUrl: `/deals/${id}`
                            }
                        });
                    }}
                />
            )
        }
    ], [id, navigate, businessEntityInput]);

    const fetchData = useCallback(async (id) => {
        return await dispatch(fetchDealById(id)).unwrap();
    }, [dispatch]);

    const updateData = useCallback(async (id, data) => {
        return await dispatch(updateDeal({id, data})).unwrap();
    }, [dispatch]);

    const tabSchema = useMemo(() => [
        {label: 'Assets', icon: <Archive size={23}/>, component: () => <Typography>I am assets</Typography>},
        {
            label: 'Attachments',
            icon: <Paperclip size={23}/>,
            component: () => <AttachmentsComponent entityLabel='deal'/>
        },
        {
            label: 'Notes',
            icon: <Notepad size={23}/>,
            component: () => <NotesComponent entityLabel='deal'/>
        },
        {
            label: 'Metadata',
            icon: <Gear size={23}/>,
            component: (props) => <MetadataComponent {...props}/>
        },
    ], []);

    return (
        <BFMTemplatePage
            component={
                <GenericEntityView
                    schema={DEAL_SCHEMA}
                    tabSchema={tabSchema}
                    fetchData={fetchData}
                    updateData={updateData}
                />
            }
        />
    );
};

export default DealView;
import axios from 'axios';
import store from "../store";
import { selectUser } from "../store/userSlice";
import { getCurrentToken } from "../services/authService";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/v1', // Configurable base URL
});

apiClient.interceptors.request.use(
    async (config) => {
        try {
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json';
            }

            const state = store.getState();
            const user = selectUser(state);
            let token = user?.token;

            if (!token) {
                token = await getCurrentToken();
            }

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            if (user?.email) {
                config.headers["RMS-User"] = user.email;
            }

            return config;
        } catch (error) {
            console.error("Interceptor Error:", error.message);
            return Promise.reject(error);
        }
    },
    (error) => Promise.reject(error)
);

const apiService = {
    get: (endpoint, params) => {
        console.log(`GET request to: ${endpoint} with params:`, params);
        return apiClient.get(endpoint, { params }).then((res) => res.data);
    },
    post: (endpoint, data, options = {}) => {
        if (data instanceof FormData) {
            console.log(`POST request to: ${endpoint} with FormData:`);
            for (const [key, value] of data.entries()) {
                console.log(`${key}: ${value}`);
            }
        } else {
            console.log(`POST request to: ${endpoint} with data:`, data);
        }
        return apiClient.post(endpoint, data, options).then((res) => res.data);
    },
    patch: (endpoint, data) => {
        console.log(`PATCH request to: ${endpoint} with data:`, data);
        return apiClient.patch(endpoint, data).then((res) => res.data);
    },
    delete: (endpoint) => {
        console.log(`DELETE request to: ${endpoint}`);
        return apiClient.delete(endpoint).then((res) => res.data);
    },
};

export default apiService;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiService from "../../api/apiService";

/**
 * Generates a Redux slice for managing entities.
 *
 * @param {string} name - The name of the slice (e.g., 'deals', 'businessEntities').
 * @param {string} endpoint - The base API endpoint for the entity (e.g., '/deals').
 * @returns {object} A Redux slice with actions and reducers for CRUD operations.
 */
const baseEntitySlice = ({name, endpoint}) => {
    const fetchEntities = createAsyncThunk(
        `${name}/fetch`,
        async ({params}, thunkAPI) => {
            try {
                const response = await apiService.get(endpoint, params);
                const arrayKey = Object.keys(response).find((key) => Array.isArray(response[key]));
                return {
                    items: response[arrayKey] || [],
                    pagination: {
                        rowCount: response.rowCount || 0,
                        offset: response.offset || 0,
                        limit: response.limit || params.limit || 9,
                    },
                };
            } catch (error) {
                return thunkAPI.rejectWithValue(error.message || `Error fetching ${name}`);
            }
        }
    );

    const fetchEntityById = createAsyncThunk(
        `${name}/fetchById`,
        async (id, thunkAPI) => {
            try {
                const response = await apiService.get(`${endpoint}/${id}`);
                return response;
            } catch (error) {
                return thunkAPI.rejectWithValue(error.message || `Error fetching ${name} by ID`);
            }
        }
    );

    const createEntity = createAsyncThunk(
        `${name}/create`,
        async (data, thunkAPI) => {
            try {
                const response = await apiService.post(endpoint, data);
                return response;
            } catch (error) {
                console.log(error)
                return thunkAPI.rejectWithValue(error.message || `Error creating ${name}`);
            }
        }
    );

    const updateEntity = createAsyncThunk(
        `${name}/update`,
        async ({id, data}, thunkAPI) => {
            try {
                const response = await apiService.patch(`${endpoint}/${id}`, data);
                return response;

            } catch (error) {
                console.log(error)
                return thunkAPI.rejectWithValue(error.message || `Error updating ${name}`);
            }
        }
    );

    const deleteEntity = createAsyncThunk(
        `${name}/delete`,
        async (id, thunkAPI) => {
            try {
                await apiService.delete(`${endpoint}/${id}`);
                return id;
            } catch (error) {
                return thunkAPI.rejectWithValue(error.message || `Error deleting ${name}`);
            }
        }
    );

    const slice = createSlice({
        name,
        initialState: {
            items: [],
            item: null,
            pagination: {rowCount: 0, offset: 0, limit: 9},
            loading: false,
            error: null,
        },
        reducers: {
            resetState: (state) => {
                state.items = [];
                state.item = null;
                state.pagination = {rowCount: 0, offset: 0, limit: 9};
                state.error = null;
            },
        },
        extraReducers: (builder) => {
            builder
                // Fetch multiple entities
                .addCase(fetchEntities.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(fetchEntities.fulfilled, (state, {payload}) => {
                    state.loading = false;
                    state.items = payload.items;
                    state.pagination = payload.pagination;
                })
                .addCase(fetchEntities.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = payload;
                })
                // Fetch a single entity by ID
                .addCase(fetchEntityById.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(fetchEntityById.fulfilled, (state, {payload}) => {
                    state.loading = false;
                    state.item = payload;
                })
                .addCase(fetchEntityById.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = payload;
                })
                // Create an entity
                .addCase(createEntity.fulfilled, (state, {payload}) => {
                    state.items.push(payload);
                })
                // Update an entity
                .addCase(updateEntity.fulfilled, (state, {payload}) => {
                    const index = state.items.findIndex((item) => item.id === payload.id);
                    if (index !== -1) {
                        state.items[index] = payload;
                    }
                    if (state.item?.id === payload.id) {
                        state.item = payload;
                    }
                })
                // Delete an entity
                .addCase(deleteEntity.fulfilled, (state, {payload: id}) => {
                    state.items = state.items.filter((item) => item.id !== id);
                    if (state.item?.id === id) {
                        state.item = null;
                    }
                });
        },
    });

    return {
        reducer: slice.reducer,
        actions: {
            ...slice.actions,
            fetchEntities,
            fetchEntityById,
            createEntity,
            updateEntity,
            deleteEntity,
        },
    };
};

export default baseEntitySlice;
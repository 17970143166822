// src/components/forms/PublishingRightsForm.js
import React, { useEffect, useState, useCallback } from "react";
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    TextField,
    Autocomplete,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Switch,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import { ExpandMore, Add, Delete } from "@mui/icons-material";
import { useFormContext, useFieldArray } from "react-hook-form";
import apiService from "../../api/apiService";
import RightsTypeIndicator from "../RightsTypeIndicator";

const PublishingRightsForm = ({ item, control }) => {
    const { setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "rights",
    });

    const [allRightsholders, setAllRightsholders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [editing] = useState(true); // For demo, always editable. Adjust as needed.

    // For creating a new right
    const [newRightType, setNewRightType] = useState("Publisher Share");

    useEffect(() => {
        const fetchInitialData = async () => {
            // If you need initial data for rightsholders, you can fetch here if needed
            // For now, this can remain empty or fetch general data
        };
        fetchInitialData();
    }, []);

    // Handle search for rightsholders depending on the type
    const handleSearch = useCallback(async (type, query) => {
        setSearching(true);
        try {
            const endpoint = type === "Publisher Share" ? "/publishers" : "/writers";
            const data = await apiService.get(endpoint, { search: query });

            // Combine publishers and writers into a single list if needed.
            // For now, we just store them in allRightsholders since user didn't specify separation.
            // We'll rely on the field type to determine what was searched.
            if (type === "Publisher Share") {
                setAllRightsholders(data.publishers || []);
            } else {
                setAllRightsholders(data.writers || []);
            }
        } catch (err) {
            console.error("Search error:", err);
        } finally {
            setSearching(false);
        }
    }, []);

    const handleAddRight = () => {
        append({
            type: newRightType,
            rightsholder_name: "",
            ipi: "",
            share: 0,
            deal_id: "",
            internal_ip_ref: "",
            mech_share: newRightType === "Publisher Share" ? 0 : undefined,
            is_controlled: newRightType === "Publisher Share" ? false : undefined,
        });
    };

    const currentRights = watch("rights");

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Rights Management
            </Typography>
            {fields.map((field, index) => {
                const type = currentRights[index]?.type || field.type;
                return (
                    <Accordion key={field.id || index}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <RightsTypeIndicator
                                    publishers={type === "Publisher Share" ? [{ ipi: field.ipi }] : []}
                                    writers={type === "Writer Share" ? [{}] : []}
                                />
                                <Typography>
                                    {field.rightsholder_name || `Right ${index + 1}`} - {field.share || 0}%
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={allRightsholders}
                                        getOptionLabel={(option) => option.name || ""}
                                        value={allRightsholders.find(
                                            (option) => option.id === field.id
                                        ) || null}
                                        onChange={(e, newValue) => {
                                            setValue(`rights.${index}.rightsholder_name`, newValue?.name || "");
                                            setValue(`rights.${index}.ipi`, newValue?.ipi || "");
                                            setValue(`rights.${index}.deal_id`, newValue?.deal_id || "");
                                            setValue(`rights.${index}.internal_ip_ref`, newValue?.internal_ip_ref || "");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Rightsholder Name"
                                                onChange={(e) => handleSearch(type, e.target.value)}
                                                fullWidth
                                                disabled={!editing}
                                            />
                                        )}
                                        loading={searching}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="IPI"
                                        value={field.ipi || ""}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Share (%)"
                                        value={field.share || ""}
                                        onChange={(e) => setValue(`rights.${index}.share`, e.target.value)}
                                        disabled={!editing}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Deal ID"
                                        value={field.deal_id || ""}
                                        onChange={(e) => setValue(`rights.${index}.deal_id`, e.target.value)}
                                        disabled={!editing}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Internal IP Ref"
                                        value={field.internal_ip_ref || ""}
                                        onChange={(e) => setValue(`rights.${index}.internal_ip_ref`, e.target.value)}
                                        disabled={!editing}
                                        fullWidth
                                    />
                                </Grid>
                                {type === "Publisher Share" && (
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Mechanical Share"
                                                value={field.mech_share || ""}
                                                onChange={(e) => setValue(`rights.${index}.mech_share`, e.target.value)}
                                                disabled={!editing}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} display="flex" alignItems="center">
                                            <Typography sx={{ mr: 2 }}>Controlled</Typography>
                                            <Switch
                                                checked={field.is_controlled || false}
                                                onChange={(e) => setValue(`rights.${index}.is_controlled`, e.target.checked)}
                                                disabled={!editing}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            {editing && (
                                <IconButton
                                    onClick={() => remove(index)}
                                    color="error"
                                    size="small"
                                    sx={{ mt: 2 }}
                                >
                                    <Delete />
                                </IconButton>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            {editing && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="right-type-label">Right Type</InputLabel>
                        <Select
                            labelId="right-type-label"
                            label="Right Type"
                            value={newRightType}
                            onChange={(e) => setNewRightType(e.target.value)}
                        >
                            <MenuItem value="Publisher Share">Publisher Share</MenuItem>
                            <MenuItem value="Writer Share">Writer Share</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={handleAddRight}
                    >
                        Create Right
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default PublishingRightsForm;
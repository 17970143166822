// src/components/forms/GenericEntityCreate.js

import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    Alert,
    Paper
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../../store/formSlice';
import FormFieldRenderer from "./FormFieldRenderer";
import apiService from '../../api/apiService';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'; // Import date-fns for date formatting

const GenericEntityCreate = ({ title, endpoint, schema, returnUrl }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    // Fetch existing form data from Redux store if available
    const formData = useSelector((state) => state.form.formData.genericEntity || {});

    // Initialize react-hook-form
    const methods = useForm({
        defaultValues: formData,
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const { handleSubmit, reset, watch } = methods;

    // Watch all fields to determine if any are files
    const watchedFields = watch();
    const hasFiles = Object.values(watchedFields).some(
        value => value instanceof File || (Array.isArray(value) && value.some(v => v instanceof File))
    );

    // Process form data: JSON or FormData based on presence of files
    const processFormData = (data) => {
        if (hasFiles) {
            // Use FormData
            const formDataObj = new FormData();

            Object.entries(data).forEach(([key, value]) => {
                if (value === undefined || value === null) return;

                if (value instanceof File) {
                    formDataObj.append(key, value);
                } else if (Array.isArray(value)) {
                    // Handle arrays (e.g., territories)
                    value.forEach((item) => {
                        formDataObj.append(`${key}[]`, item);
                    });
                } else if (typeof value === 'object') {
                    // Handle objects (like autocomplete selections)
                    formDataObj.append(key, value.id || JSON.stringify(value));
                } else {
                    // Handle primitive values
                    formDataObj.append(key, value);
                }
            });

            return { data: formDataObj, headers: { 'Content-Type': 'multipart/form-data' } };
        } else {
            // Use JSON
            const jsonData = {};

            Object.entries(data).forEach(([key, value]) => {
                if (value === undefined || value === null) return;

                if (Array.isArray(value)) {
                    jsonData[key] = value;
                } else if (typeof value === 'object') {
                    jsonData[key] = value.id || value; // Assuming autocomplete returns an object with 'id'
                } else {
                    jsonData[key] = value;
                }
            });

            return { data: jsonData, headers: { 'Content-Type': 'application/json' } };
        }
    };

    // Form submission handler
    const onSubmit = async (data) => {
        setError(null);
        setIsSubmitting(true);

        try {
            // **Format 'completed_date' to 'YYYY-MM-DD'**
            if (data.completed_date instanceof Date && !isNaN(data.completed_date)) {
                data.completed_date = format(data.completed_date, 'yyyy-MM-dd');
            } else if (typeof data.completed_date === 'string') {
                // In case it's already a string but needs to be formatted
                const date = new Date(data.completed_date);
                if (!isNaN(date)) {
                    data.completed_date = format(date, 'yyyy-MM-dd');
                }
            }

            const { data: payload, headers } = processFormData(data);

            // **Logging the Payload and Headers**
            if (headers['Content-Type'] === 'application/json') {
                console.log('Submitting JSON Data:', payload);
            } else if (payload instanceof FormData) {
                // Convert FormData to a plain object for logging (Note: Files won't be displayed)
                const plainObject = {};
                for (let [key, value] of payload.entries()) {
                    plainObject[key] = value instanceof File ? value.name : value;
                }
                console.log('Submitting FormData:', plainObject);
            }
            console.log('Headers:', headers);

            // Submit the form
            const response = await apiService.post(endpoint, payload, { headers });

            // **Logging API Response**
            console.log('API Response:', response);

            // Update Redux store
            dispatch(updateField({
                formType: 'genericEntity',
                data: {
                    ...data,
                }
            }));

            // Navigate to success page or previous page
            navigate(returnUrl || -1, { replace: true });
        } catch (err) {
            console.error('Submission Error:', err);

            // **Enhanced Error Handling: Ensure errorMessage is a string**
            let errorMessage = 'An unexpected error occurred.';
            if (err.response && err.response.data) {
                if (err.response.data.detail && Array.isArray(err.response.data.detail)) {
                    // Extract messages from each detail object
                    errorMessage = err.response.data.detail.map(e => e.msg).join(' ');
                } else if (typeof err.response.data.message === 'string') {
                    errorMessage = err.response.data.message;
                } else if (typeof err.response.data.message === 'object') {
                    // If message is an object with multiple error details
                    errorMessage = Object.values(err.response.data.message).map(item => item.msg).join(' ');
                } else if (typeof err.response.data.detail === 'string') {
                    errorMessage = err.response.data.detail;
                } else {
                    // Fallback to stringified data
                    errorMessage = JSON.stringify(err.response.data.message);
                }
            } else if (err.message) {
                errorMessage = err.message;
            }

            setError(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Reset form to default values
    const handleReset = () => {
        methods.reset(formData);
        setError(null);
    };

    return (
        <FormProvider {...methods}>
            <Paper elevation={2} sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
                <Typography variant="h5" gutterBottom>{title}</Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Box display="flex" flexDirection="column" gap={3}>
                        {schema.map((field) => (
                            <Box key={field.name} sx={{ mb: 3 }}>
                                <FormFieldRenderer field={field} />
                            </Box>
                        ))}

                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                            >
                                {isSubmitting ? 'Saving...' : 'Submit'}
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleReset}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Paper>
        </FormProvider>
    );

};

export default GenericEntityCreate;
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    Avatar,
    Box,
    Container,
    Drawer,
    List,
    ListItemButton,
    ListSubheader,
    InputBase,
    Divider,
    IconButton,
    Tooltip,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Menu as MenuIcon, HelpOutline as HelpIcon, Search as SearchIcon} from "@mui/icons-material";
import {
    Building,
    Handshake,
    PaintBrush,
    VinylRecord,
    MusicNoteSimple,
    Playlist,
    Barcode,
    PencilLine,
    PianoKeys,
    Pipe,
    Wrench, Microphone, ArrowCircleDownLeft, ArrowCircleLeft
} from "@phosphor-icons/react";
import {useSelector, useDispatch} from "react-redux";
import {signOutUser} from "../../services/authService";

const navData = {
    general: [
        {label: "Business Entities", route: "/business-entities", icon: <Building size={23}/>},
        {label: "Deals", route: "/deals", icon: <Handshake size={23}/>},
    ],
    recordings: [
        {label: "Artists", route: "/recordings/artists", icon: <PaintBrush size={23}/>},
        {label: "Recordings", route: "/recordings/recordings", icon: <Microphone size={23}/> },
        {label: "Tracks", route: "/recordings/tracks", icon: <MusicNoteSimple size={23}/>},
        {label: "Releases", route: "/recordings/releases", icon: <VinylRecord size={23}/>},
        {label: "Products", route: "/recordings/products", icon: <Barcode size={23}/>},
    ],
    publishing: [
        {label: "Writers", route: "/publishing/writers", icon: <PencilLine size={23}/>},
        {label: "Works", route: "/publishing/works", icon: <PianoKeys size={23}/>},
        {label: "Publishers", route: "/publishing/publishers", icon: <Pipe size={23}/>},
    ],
    utilities: [
        {label: "Utilities", route: "/utilities", icon: <Wrench size={23}/>},
    ],
};

function Navbar() {
    const [drawerExpanded, setDrawerExpanded] = useState(
        JSON.parse(localStorage.getItem("drawerExpanded")) ?? true
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.setItem("drawerExpanded", JSON.stringify(drawerExpanded));
    }, [drawerExpanded]);

    const handleSignOut = () => {
        setAnchorEl(null);
        signOutUser(dispatch);
    };

    const renderSidePanel = () => (
        <Box
            sx={{
                width: drawerExpanded ? 250 : 70,
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "black",
                color: "var(--white)",
                transition: "width 0.4s ease-in-out",
            }}
        >
            <IconButton
                sx={{
                    color: "var(--white)",
                    margin: "10px auto",
                    transform: drawerExpanded ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 0.3s ease-in-out",
                }}
                onClick={() => setDrawerExpanded((prev) => !prev)}
            >
                {/*<MenuIcon/>*/}
                <ArrowCircleLeft/>
            </IconButton>
            <List sx={{flexGrow: 1, pt: 2}}>
                {Object.entries(navData).map(([parentKey, items]) => (
                    <React.Fragment key={parentKey}>

                        <Divider/>
                        <ListSubheader
                            sx={{
                                backgroundColor: "black",
                                color: "var(--orange)",
                                fontWeight: "bold",
                                display: drawerExpanded ? "block" : "none",
                                transition: "opacity 0.3s ease-in-out",
                                opacity: drawerExpanded ? 1 : 0,
                            }}
                        >
                            {parentKey.charAt(0).toUpperCase() + parentKey.slice(1)}
                        </ListSubheader>
                        {items.map((item) => (
                            <Tooltip
                                title={!drawerExpanded ? item.label : ""}
                                placement="right"
                                arrow
                                key={item.route}
                            >
                                <ListItemButton
                                    component={Link}
                                    to={item.route}
                                    sx={{
                                        color: "var(--white)",
                                        justifyContent: drawerExpanded ? "initial" : "center",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        transition: "all 0.3s ease-in-out",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: 50,
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                    <ListItemText
                                        primary={item.label}
                                        sx={{
                                            ml: 0, // Adjusted for tighter spacing
                                            whiteSpace: "nowrap",
                                            opacity: drawerExpanded ? 1 : 0,
                                            transition: "opacity 0.3s ease-in-out",
                                        }}
                                    />
                                </ListItemButton>
                            </Tooltip>
                        ))}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'black',
                    zIndex: theme.zIndex.drawer + 1,
                    transition: "all 0.4s ease-in-out",
                    borderBottom: '1px solid white'

                }}
            >
                <Container maxWidth="xl">
                    <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <img
                                src="/BFM.jpeg"
                                alt="Logo"
                                style={{
                                    width: 40,
                                    height: 40,
                                    transition: "opacity 0.4s ease",
                                }}
                            />
                            <Typography
                                variant="h6"
                                component={Link}
                                to="/"
                                color="inherit"
                                sx={{textDecoration: "none", fontFamily: "var(--sans)"}}
                            >
                                RMS
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: 1,
                                    px: 2,
                                    flexGrow: 1,
                                }}
                            >
                                <SearchIcon/>
                                <InputBase
                                    placeholder="Search..."
                                    inputProps={{"aria-label": "search"}}
                                    sx={{ml: 1, color: "inherit", flex: 1}}
                                />
                            </Box>

                            {user ? (
                                <>
                                    <Avatar
                                        src={user.photoURL}
                                        alt={user.displayName || "User"}
                                        sx={{width: 32, height: 32, cursor: "pointer"}}
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                    />
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component={Link}
                                    to="/signin"
                                    sx={{textDecoration: "none", color: "white"}}
                                >
                                    Sign In
                                </Typography>
                            )}

                            <Tooltip title="Get help">
                                <IconButton color="inherit">
                                    <HelpIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerExpanded ? 250 : 70,
                        boxSizing: "border-box",
                        marginTop: 8,
                        backgroundColor: "black",
                        color: "var(--white)",
                        transition: "width 0.4s ease-in-out",
                    },
                }}
            >
                {renderSidePanel()}
            </Drawer>

            <Box
                sx={{
                    marginLeft: drawerExpanded ? 250 : 70,
                    marginTop: 8,
                    padding: 3,
                    transition: "margin-left 0.4s ease-in-out",
                }}
            />
        </>
    );
}

export default Navbar;
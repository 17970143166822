import React from 'react';
import { Box, Grid, Typography, Divider, TextField } from '@mui/material';

const MetadataComponent = ({ item }) => {
    const metadataFields = [
        { label: 'Create Method', dataKey: 'create_method' },
        { label: 'Created By', dataKey: 'created_by' },
        { label: 'Created At', dataKey: 'created_at', type: 'datetime-local' },
        { label: 'Updated By', dataKey: 'updated_by' },
        { label: 'Updated At', dataKey: 'updated_at', type: 'datetime-local' },
    ];

    return (
        <Box p={2}>
            <Typography variant="h6" mb={2}>
                Metadata
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
                {metadataFields.map((field, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <TextField
                            label={field.label}
                            value={item?.[field.dataKey] || ''}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                sx: {
                                    pointerEvents: 'none', // Disables all user interaction
                                    backgroundColor: '#f0f0f0', // Grey background
                                    color: '#6c6c6c', // Slightly dimmed text color
                                    border: 'none',
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                sx: {
                                    color: '#6c6c6c', // Dimmed label color
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#d6d6d6', // Grey border color
                                    },
                                },
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MetadataComponent;
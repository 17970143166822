import React, { useState, useCallback } from 'react';
import {
    Box,
    Button,
    Typography,
    Divider,
    Alert,
    Stepper,
    Step,
    StepLabel,
    Paper,
    CircularProgress
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../../store/formSlice';
import FormFieldRenderer from "./FormFieldRenderer";
import scheduleOfWorkSchema from "./schemas/scheduleOfWorkSchema";
import apiService from '../../api/apiService';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'; // Import date-fns for date formatting

const ScheduleOfWorkForm = ({ isPublishing }) => {
    // State management
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formData = useSelector((state) => state.form.formData.scheduleOfWork || {});

    // Set defaultValues with 'is_controlled' initialized to false if not present
    const defaultValues = {
        ...formData,
        is_controlled: formData.is_controlled !== undefined ? formData.is_controlled : false,
    };

    // Initialize form with react-hook-form
    const methods = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const {
        handleSubmit,
        trigger,
        watch,
        formState: { errors, isDirty }
    } = methods;

    // Track completion status for each step
    const steps = scheduleOfWorkSchema.map((step, index) => {
        const stepFields = step.fields.map(field => field.name);
        const hasErrors = stepFields.some(fieldName => errors[fieldName]);
        const isStepDirty = stepFields.some(fieldName => {
            const value = watch(fieldName);
            // Consider fields with any value as dirty
            return value !== undefined && value !== null && value !== '';
        });

        return {
            ...step,
            completed: isStepDirty && !hasErrors && stepFields.every(fieldName => {
                const field = step.fields.find(f => f.name === fieldName);
                const value = watch(fieldName);
                return !field.required || (value !== undefined && value !== null && value !== '');
            })
        };
    });

    // Process form data for submission
    const processFormData = useCallback((data) => {
        const formDataObj = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value === undefined || value === null) return; // Skip undefined or null values

            if (key === 'file') {
                // Handle file upload
                if (Array.isArray(value) && value.length > 0) {
                    formDataObj.append(key, value[0]); // Assuming single file upload
                } else if (value instanceof File) {
                    formDataObj.append(key, value);
                }
            } else if (key === 'territories') {
                // Handle territories array
                if (Array.isArray(value)) {
                    value.forEach((territory) => {
                        formDataObj.append('territories', territory); // Append each as 'territories'
                    });
                } else {
                    formDataObj.append(key, value);
                }
            } else if (Array.isArray(value)) {
                // Handle other arrays
                value.forEach((item) => {
                    formDataObj.append(`${key}[]`, item);
                });
            } else if (value instanceof Date) {
                // Handle dates
                formDataObj.append(key, format(value, 'yyyy-MM-dd')); // Ensure correct format
            } else if (typeof value === 'object' && value !== null) {
                // Handle objects (like autocomplete selections)
                formDataObj.append(key, value.id || JSON.stringify(value));
            } else {
                // Handle primitive values
                formDataObj.append(key, value);
            }
        });

        return formDataObj;
    }, []);

    // Form submission handler
    const onSubmit = async (data) => {
        setError(null);
        setIsSubmitting(true);

        try {
            // **Log Form Data Before Processing**
            console.log('Form Data Before Processing:', data);

            const endpoint = `/import/schedule/${isPublishing ? 'publishing' : 'recordings'}`;
            const formDataPayload = processFormData(data);

            // **Logging the Payload and Headers**
            console.log('Submitting to Endpoint:', endpoint);
            if (formDataPayload instanceof FormData) {
                // For FormData, list keys and values (files as names)
                const plainObject = {};
                for (let [key, value] of formDataPayload.entries()) {
                    if (key === 'file') {
                        plainObject[key] = {
                            name: value.name,
                            type: value.type,
                            size: value.size,
                        };
                    } else if (key === 'territories') {
                        if (!plainObject[key]) plainObject[key] = [];
                        plainObject[key].push(value);
                    } else {
                        plainObject[key] = value;
                    }
                }
                console.log('Submitting FormData:', plainObject);
            }
            console.log('Headers:', {
                'Content-Type': 'multipart/form-data'
            });

            // Submit the form using axios (assuming apiService uses axios)
            const response = await apiService.post(endpoint, formDataPayload, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure multipart/form-data
                }
            });

            // **Logging API Response**
            console.log('API Response:', response.data);

            // Update Redux store
            dispatch(updateField({
                formType: 'scheduleOfWork',
                data: {
                    ...data,
                }
            }));

            // Navigate to success page or show success message without submissionId
            navigate('/success', {
                state: {
                    message: 'Schedule uploaded successfully',
                }
            });

        } catch (err) {
            console.error('Submission Error:', err);

            // **Enhanced Error Handling: Ensure errorMessage is a string**
            let errorMessage = 'An unexpected error occurred.';
            if (err.response && err.response.data) {
                if (err.response.data.detail && Array.isArray(err.response.data.detail)) {
                    // Extract messages from each detail object
                    errorMessage = err.response.data.detail.map(e => e.msg).join(' ');
                } else if (typeof err.response.data.message === 'string') {
                    errorMessage = err.response.data.message;
                } else if (typeof err.response.data.message === 'object') {
                    // If message is an object with multiple error details
                    errorMessage = Object.values(err.response.data.message).map(item => item.msg).join(' ');
                } else if (typeof err.response.data.detail === 'string') {
                    errorMessage = err.response.data.detail;
                } else {
                    // Fallback to stringified data
                    errorMessage = JSON.stringify(err.response.data.message);
                }
            } else if (err.message) {
                errorMessage = err.message;
            }

            setError(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Navigation handlers
    const handleNext = async () => {
        const currentStepSchema = scheduleOfWorkSchema[activeStep];
        const currentFields = currentStepSchema.fields.map(field => field.name);

        try {
            const isValid = await trigger(currentFields);

            if (isValid) {
                if (activeStep === steps.length - 1) {
                    handleSubmit(onSubmit)();
                } else {
                    setActiveStep(prev => prev + 1);
                }
            }
        } catch (validationError) {
            console.error('Validation Error:', validationError);
        }
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
        setError(null); // Clear any errors when moving back
    };

    // Reset form handler
    const handleReset = () => {
        methods.reset(defaultValues); // Reset to initial default values
        setActiveStep(0);
        setError(null);
    };

    return (
        <FormProvider {...methods}>
            <Paper elevation={3} sx={{ p: 4, maxWidth: '40vw', mx: 'auto' }}>
                {/* Header */}
                <Typography variant="h5" gutterBottom>
                    {isPublishing ? 'Publishing' : 'Recording'} Schedule Import
                </Typography>

                {/* Progress Stepper */}
                <Stepper activeStep={activeStep} sx={{ my: 4 }}>
                    {steps.map((step, index) => (
                        <Step key={index} completed={step.completed}>
                            <StepLabel error={!!errors[step.fields[0]?.name]}>
                                {step.label || `Step ${index + 1}`}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/* Error Alert */}
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
                        {error}
                    </Alert>
                )}

                {/* Form Fields */}
                <Box sx={{ minHeight: 300 }}>
                    {steps[activeStep].fields.map((field) => (
                        <Box key={field.name} sx={{ mb: 3 }}>
                            <FormFieldRenderer field={field} />
                        </Box>
                    ))}
                </Box>

                {/* Navigation Buttons */}
                <Divider sx={{ my: 3 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Button
                            onClick={handleBack}
                            disabled={activeStep === 0 || isSubmitting}
                            variant="outlined"
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={handleReset}
                            disabled={isSubmitting}
                            color="inherit"
                        >
                            Reset
                        </Button>
                    </Box>
                    <Button
                        onClick={handleNext}
                        variant="contained"
                        disabled={isSubmitting || !steps[activeStep].completed}
                        startIcon={isSubmitting && <CircularProgress size={20} />}
                    >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </Paper>
        </FormProvider>
    )

};

// Export variants
export const ScheduleOfWorkFormPublishing = () => <ScheduleOfWorkForm isPublishing />;
export const ScheduleOfWorkFormRecordings = () => <ScheduleOfWorkForm isPublishing={false} />;
export default ScheduleOfWorkForm;
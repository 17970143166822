import { createTheme } from '@mui/material/styles';

// Custom color palette for dark theme
const darkPalette = {
    primary: {
        main: '#90caf9',  // A lighter blue that stands out in dark mode
        light: '#e3f2fd',
        dark: '#42a5f5',
    },
    secondary: {
        main: '#ce93d8',  // A soft purple for secondary actions
        light: '#f3e5f5',
        dark: '#ab47bc',
    },
    error: {
        main: '#f44336',
        light: '#e57373',
        dark: '#d32f2f',
    },
    warning: {
        main: '#ffa726',
        light: '#ffb74d',
        dark: '#f57c00',
    },
    info: {
        main: '#29b6f6',
        light: '#4fc3f7',
        dark: '#0288d1',
    },
    success: {
        main: '#66bb6a',
        light: '#81c784',
        dark: '#388e3c',
    },
    background: {
        default: '#121212',  // Material Design recommended dark mode background
        paper: '#1e1e1e',    // Slightly lighter than the background for paper elements
        elevation1: '#242424',
        elevation2: '#2a2a2a',
        elevation3: '#303030',
    },
    text: {
        primary: '#ffffff',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
};

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        ...darkPalette,
    },
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: darkPalette.background.default,
                    color: darkPalette.text.primary,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
            defaultProps: {
                elevation: 1,
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: darkPalette.background.paper,
                        '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.4)',
                        },
                        '& .MuiInputBase-input': {
                            color: darkPalette.text.primary, // Set the text color to white
                        },
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: darkPalette.background.paper, // Set the background color for the input container
                        color: darkPalette.text.primary, // Set the text color to white
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: darkPalette.background.paper,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: darkPalette.background.paper,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: darkPalette.background.paper,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${darkPalette.divider}`,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: darkPalette.background.paper,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: darkPalette.text.secondary,
                    '&.Mui-selected': {
                        color: darkPalette.primary.main,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    backgroundColor: darkPalette.background.paper, // Background color for Input
                    color: darkPalette.text.primary, // White text color
                    '&:before': {
                        borderBottom: `1px solid ${darkPalette.divider}`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `2px solid ${darkPalette.text.primary}`,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: darkPalette.background.paper, // Background color for InputBase
                    color: darkPalette.text.primary, // Text color set to white
                    '&.Mui-focused': {
                        backgroundColor: darkPalette.background.paper, // Background color stays when focused
                    },
                    '& .MuiInputBase-input': {
                        color: darkPalette.text.primary, // Text color for the input inside
                    },
                },
            },
        },
    },
});
// NoteCreate.jsx
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {createNote} from "../store/noteSlice";

const NoteCreate = ({ nodeLabel }) => {
    const [note, setNote] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const handleSave = async () => {
        if (!note.trim()) {
            setError('Note cannot be empty.');
            return;
        }

        try {
            await dispatch(createNote({
                    note,
                    entity_id: id,
                    node_label: nodeLabel

            })).unwrap();
            navigate(-1);
        } catch (err) {
            setError(err?.message || 'Failed to save the note.');
        }
    };

    return (
        <Box p={2}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5" mb={3}>Add Note</Typography>
                <TextField
                    label="Note"
                    fullWidth
                    multiline
                    rows={4}
                    value={note}
                    onChange={e => {
                        setError('');
                        setNote(e.target.value);
                    }}
                    error={!!error}
                    helperText={error}
                />
                <Box mt={3} display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default NoteCreate;
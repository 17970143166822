import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, CircularProgress, Alert, Grid, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataCard from "./data/DataCard";
import ItemsPerPageSelector from "./ItemsPerPageSelector";

/**
 * A reusable list component that handles common listing functionality
 * @param {Object} props - Component props
 * @param {string} props.title - The title to display for the list
 * @param {string} props.entityType - Type of entity (e.g., 'deals', 'writers', 'business-entities')
 * @param {Object} props.schema - Schema configuration for DataCard
 * @param {Function} props.fetchAction - Redux action to fetch items
 * @param {Function} props.resetAction - Redux action to reset items
 * @param {Object} props.additionalParams - Additional parameters for the fetch action
 * @param {Object} props.selector - Redux selector configuration
 * @param {string} props.createPath - Path for the create button navigation
 */
const EntityList = ({
                        title,
                        entityType,
                        schema,
                        fetchAction,
                        resetAction,
                        additionalParams = {},
                        selector,
                        createPath
                    }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Get state from Redux using the provided selector
    const { items, loading, error, pagination } = useSelector(selector);

    // Pagination state
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        // Combine pagination params with any additional params
        const params = {
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
            ...additionalParams
        };

        dispatch(fetchAction({ params }));

        // Cleanup function to reset state when component unmounts
        return () => {
            if (resetAction && !additionalParams.parentId) {
                dispatch(resetAction());
            }
        };
    }, [dispatch, page, itemsPerPage, fetchAction, resetAction, additionalParams]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setPage(1);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">{title}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(createPath)}
                >
                    Create New {title.slice(0, -1)} {/* Removes plural 's' */}
                </Button>
            </Box>

            <ItemsPerPageSelector
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
            />

            {items.length > 0 ? (
                <>
                    <Grid container spacing={3} mb={4}>
                        {items.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item.id}>
                                <DataCard
                                    data={item}
                                    schema={schema}
                                    onCardClick={() => navigate(`/${entityType}/${item.id}`)}
                                    actionButtons={[
                                        {
                                            label: 'View Details',
                                            onClick: (data) => navigate(`/${entityType}/${data.id}`),
                                        },
                                    ]}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(pagination.rowCount / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <Typography>No {title.toLowerCase()} available.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default EntityList;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formData: {}, // Store form data keyed by form name
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updateField: (state, action) => {
            const { formName, fieldName, value } = action.payload;
            if (!state.formData[formName]) {
                state.formData[formName] = {};
            }
            state.formData[formName][fieldName] = value;
        },
        resetForm: (state, action) => {
            const { formName } = action.payload;
            state.formData[formName] = {};
        },
    },
});

export const { updateField, resetForm } = formSlice.actions;
export default formSlice.reducer;
// src/components/CountrySelector.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Autocomplete,
    TextField,
    IconButton,
    FormControl,
    FormHelperText,
    Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { useController } from 'react-hook-form';
import { regions, countryOptions } from '../../data/countries';

const geoUrl = 'https://unpkg.com/world-atlas@2/countries-110m.json';

const CountrySelector = ({ name, control, rules }) => {
    // Integration with react-hook-form
    const {
        field: {value = [], onChange},
        fieldState: {error}
    } = useController({
        name,
        control,
        rules,
        defaultValue: []
    });

    // Convert selected values (strings) to country objects
    const selectedCountries = countryOptions.filter(c => value.includes(c.value));

    const [searchValue, setSearchValue] = useState('');
    const [position, setPosition] = useState({coordinates: [0, 0], zoom: 1.2});
    const [containerRef, setContainerRef] = useState(null);
    const [dimensions, setDimensions] = useState({width: 800, height: 400});

    // Update dimensions on mount and resize
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef) {
                setDimensions({
                    width: containerRef.offsetWidth,
                    height: containerRef.offsetHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [containerRef]);

    // Map navigation handlers
    const handleMoveEnd = useCallback((position) => {
        setPosition(position);
    }, []);

    const handleZoomIn = useCallback(() => {
        if (position.zoom >= 4) return;
        setPosition(prev => ({...prev, zoom: Math.min(prev.zoom * 1.5, 4)}));
    }, [position.zoom]);

    const handleZoomOut = useCallback(() => {
        if (position.zoom <= 0.8) return;
        setPosition(prev => ({...prev, zoom: Math.max(prev.zoom / 1.5, 0.8)}));
    }, [position.zoom]);

    // Handler for Autocomplete selection
    const handleCountryChange = (event, newValue) => {
        const newValues = newValue.map(country => country.value);
        onChange(newValues);
    };

    // Handler for map clicks
    const handleCountryClick = useCallback((geo) => {
        const country = countryOptions.find(c => c.id === geo.id);
        if (country) {
            const isSelected = value.includes(country.value);
            const updatedValues = isSelected
                ? value.filter(v => v !== country.value)
                : [...value, country.value];
            onChange(updatedValues);
        }
    }, [value, onChange]);

    // Quick select handlers
    const selectAll = () => {
        const allValues = countryOptions.map(c => c.value);
        onChange(allValues);
    };

    const addRegion = (regionCodes) => {
        const newCountries = countryOptions.filter(c => regionCodes.includes(c.value));
        const newValues = newCountries.map(c => c.value);
        const uniqueValues = [...new Set([...value, ...newValues])];
        onChange(uniqueValues);
    };

    const deselectAll = () => {
        onChange([]);
    };

    return (
        <FormControl error={!!error} fullWidth>
            <Grid container spacing={2}>
                {/* Quick Select Panel */}
                <Grid item xs={12} sm={4} md={3}>
                    <Typography variant="subtitle1" sx={{mb: 1.5, color: 'text.primary', fontWeight: 500}}>
                        Quick Select
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                        <Button onClick={selectAll} variant="contained" size="small">
                            Worldwide
                        </Button>
                        {Object.entries(regions).map(([region, codes]) => (
                            <Button
                                key={region}
                                onClick={() => addRegion(codes)}
                                variant="outlined"
                                size="small"
                            >
                                {region}
                            </Button>
                        ))}
                        <Button
                            onClick={deselectAll}
                            variant="outlined"
                            color="error"
                            size="small"
                        >
                            Clear Selection
                        </Button>
                    </Box>
                </Grid>

                {/* Map and Autocomplete */}
                <Grid item xs={12} sm={8} md={9}>
                    {/* Autocomplete */}
                    <Autocomplete
                        multiple
                        options={countryOptions}
                        getOptionLabel={(option) => `${option.flag} ${option.label}`}
                        value={selectedCountries}
                        onChange={handleCountryChange}
                        inputValue={searchValue}
                        onInputChange={(_, newValue) => setSearchValue(newValue)}
                        renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                                <CheckIcon fontSize="small"
                                           color={value.includes(option.value) ? 'primary' : 'disabled'} sx={{mr: 1}}/>
                                <Typography variant="body2">
                                    {option.flag} {option.label}
                                </Typography>
                            </li>
                        )}
                        renderTags={() => null} // Hide the chips
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Search countries..."
                                size="small"
                                error={!!error}
                                helperText={error ? error.message : ''}
                            />
                        )}
                        disableCloseOnSelect
                        sx={{mb: 2}}
                    />

                    {/* Map Controls */}
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                        <IconButton onClick={handleZoomIn} disabled={position.zoom >= 4} size="small">
                            <AddIcon fontSize="small"/>
                        </IconButton>
                        <IconButton onClick={handleZoomOut} disabled={position.zoom <= 0.8} size="small">
                            <RemoveIcon fontSize="small"/>
                        </IconButton>
                    </Box>

                    {/* Map */}
                    <Box ref={(node) => setContainerRef(node)} sx={{width: '100%', height: 400, position: 'relative'}}>
                        <ComposableMap
                            projectionConfig={{
                                scale: 170,
                                center: [0, 0],
                                rotate: [-10, 0, 0],
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        >
                            <ZoomableGroup
                                zoom={position.zoom}
                                center={position.coordinates}
                                onMoveEnd={handleMoveEnd}
                                maxZoom={4}
                                minZoom={0.8}
                                translateExtent={[
                                    [-dimensions.width * 0.5, -dimensions.height * 0.5],
                                    [dimensions.width * 0.5, dimensions.height * 0.5],
                                ]}
                            >
                                <Geographies geography={geoUrl}>
                                    {({geographies}) =>
                                        geographies.map((geo) => {
                                            const country = countryOptions.find(c => c.id === geo.id);
                                            const isSelected = country ? value.includes(country.value) : false;
                                            return (
                                                <Geography
                                                    key={geo.rsmKey}
                                                    geography={geo}
                                                    fill={isSelected ? '#1976D2' : '#E5E5E5'}
                                                    stroke="#FFF"
                                                    strokeWidth={0.3}
                                                    style={{
                                                        default: {
                                                            outline: 'none',
                                                            transition: 'all 0.2s',
                                                        },
                                                        hover: {
                                                            fill: isSelected ? '#1565C0' : '#D5D5D5',
                                                            outline: 'none',
                                                            cursor: 'pointer',
                                                        }
                                                    }}
                                                    onClick={() => handleCountryClick(geo)}
                                                />
                                            );
                                        })
                                    }
                                </Geographies>
                            </ZoomableGroup>
                        </ComposableMap>
                    </Box>

                    {/* Selected Countries Count */}
                    <Typography variant="caption" sx={{mt: 1}}>
                        {value.length} countries selected
                    </Typography>
                </Grid>
            </Grid>
        </FormControl>
    );
}

export default CountrySelector;
import createEntitySlice from './base/baseEntitySlice';

const { reducer, actions } = createEntitySlice({
    name: 'works',
    endpoint: '/works',
});

export const {
    fetchEntities: fetchWorks,
    fetchEntityById: fetchWorkById,
    createEntity: createWork,
    updateEntity: updateWork,
    deleteEntity: deleteWork,
    resetState: resetWorks,
} = actions;

export default reducer;
import React from 'react';
import EntityList from '../../components/EntityList';

import {fetchWriters, resetWriters} from "../../store/writerSlice";

const DEAL_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        // {key: 'id', label: 'RMS ID', variant: 'body2', color: 'text.secondary'},
        {key:'ipi', label: 'IPI', variant: 'body2'}
    ],
    chip: {key: 'id'}
    // metadata: [
    //     {key: 'created_by', label: 'Created By', variant: 'body2'},
    //     {key: 'created_at', label: 'Created At', format: (value) => new Date(value).toLocaleDateString()},
    // ],
};

const WritersList = ({businessEntityId = null}) => {
    const additionalParams = businessEntityId ? {businessEntityId} : {};

    return (
        <EntityList
            title="Writers"
            entityType="writers"
            schema={DEAL_SCHEMA}
            fetchAction={fetchWriters}
            resetAction={resetWriters}
            additionalParams={additionalParams}
            selector={(state) => state.writers}
            createPath="/writers/create"
        />
    );
};

export default WritersList;
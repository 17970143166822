// src/components/FormRegistry.js
import {ScheduleOfWorkFormPublishing, ScheduleOfWorkFormRecordings} from '../ScheduleOfWorkForm'

export const FORM_REGISTRY = {
    scheduleOfWork: {
        publishing: {
            component: ScheduleOfWorkFormPublishing,
            endpoint: '/import/schedule/publishing',
        },
        recordings: {
            component: ScheduleOfWorkFormRecordings,
            endpoint: '/import/schedule/recordings',
        },
    },
};

export const getFormConfig = (mainCategory, subCategory) =>
    FORM_REGISTRY[mainCategory]?.[subCategory] || null;